.code-highlight {
  --dex-code-comment-color: var(--core-fill-20-color);
  --dex-code-keyword-color: var(--core-red-fill-color);
  --dex-code-number-color: var(--core-red-fill-color);
  --dex-code-variable-color: var(--core-teal-text-color);
  --dex-code-tag-color: var(--core-red-fill-color);
  --dex-code-attribute-color: var(--core-text-20-color);
  --dex-code-formula-color: var(--core-text-20-color);
  --dex-code-addition-color: var(--core-text-20-color);
  --dex-code-deletion-color: var(--core-red-fill-color);
  --dex-code-default-color: var(--core-text-10-color);
}


/* General Purpose Styles */
.code-highlight :global .token.comment {
  color: var(--dex-code-comment-color);
  line-height: 24px;
}

.code-highlight :global .token.keyword,
.code-highlight :global .token.regex,
.code-highlight :global .token.url {
  color: var(--dex-code-keyword-color);
}

.code-highlight :global .token.builtin,
.code-highlight :global .token.class-name,
.code-highlight :global .token.function,
.code-highlight :global .token.symbol {
  color: var(--dex-code-tag-color)
}

.code-highlight :global .token.boolean,
.code-highlight :global .token.number {
  color: var(--dex-code-number-color);
}

.code-highlight :global .token.string,
.code-highlight :global .token.char,
.code-highlight :global .token.variable,
.code-highlight :global .token.constant,
.code-highlight :global .token.important {
  color: var(--dex-code-variable-color);
}

/* Markup */
.code-highlight :global .token.tag {
  color: var(--dex-code-tag-color);
}

.code-highlight :global .token.attr-name {
  color: var(--dex-code-attribute-color);
}

.code-highlight :global .token.namespace,
.code-highlight :global .token.entity {
  color: var(--dex-code-tag-color);
}

.code-highlight :global .token.prolog,
.code-highlight :global .token.cdata,
.code-highlight :global .token.doctype {
  color: var(--dex-code-comment-color);
}

/* Diff */
.code-highlight :global .token.deleted {
  background-color: var(--dex-code-deletion-color);
}

.code-highlight :global .token.inserted {
  background-color: var(--dex-code-addition-color);
}

/* C# Overrides */
.code-highlight.language-csharp :global .token.constructor-invocation.class-name {
  color: var(--dex-code-default-color);
}

.code-highlight.language-csharp :global .token.return-type.class-name {
  color: var(--dex-code-default-color);
}

/* Java Overrides */
.code-highlight.language-java :global .token.namespace {
  color: var(--dex-code-default-color);
}

/* Misc Overrides */
.code-highlight :global .token.bold {
  font-weight: 400;
}